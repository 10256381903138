import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  concat,
} from '@apollo/client';

const API_URL = process.env.NODE_ENV === 'development'
  ? '/graphql'
  : `${process.env.REACT_APP_SERVER_URL}/graphql`;

const httpLink = createHttpLink({
  uri: API_URL,
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: new URLSearchParams(window.location.search).get('token'),
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export { client as default };
