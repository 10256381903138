/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  LabelList,
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import styled from 'styled-components';

import Loading from '../Loading';
import NoCouponCodes from '../NoCouponCodes';

const PROGRESS = gql`
  query ($select: ProgressSelectInput) {
    progress(select: $select) {
      levelCount
      progress {
        levelTitle
        levelPosition
        startedCount
        completedCount
      }
    }
  }
`;

const colors = ['rgb(218, 39, 110)', 'rgb(22, 160, 133)'];

const TooltipStyled = styled.div`
  background-color: rgba(250, 250, 250, 0.8);
  padding: 4px;
  border: 2px solid;
  border-color: var(--color-light-gray);
  border-radius: 4px;
  .text {
    padding: 0;
    margin: 0;
  }
  .title {
    font-weight: bold;
  }
  .started {
    color: rgb(218, 39, 110);
  }
  .completed {
    color: rgb(22, 160, 133);
  }
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipStyled>
        <p className="text title">{`Level ${label}`}</p>
        <p className="text">{get(payload, '[0].payload.levelTitle')}</p>
        <p className="text started">{`Started: ${get(payload, '[0].payload.Started')}`}</p>
        <p className="text completed">{`Completed: ${get(payload, '[0].payload.Completed')}`}</p>
      </TooltipStyled>
    );
  }

  return null;
};

export default function GuideProgress({ couponCodes, loadingParent }) {
  const { data, loading } = useQuery(PROGRESS, {
    variables: {
      select: { couponCodes },
    },
    skip: couponCodes.length === 0,
  });
  const numLevels = get(data, 'progress.levelCount', 36);
  const results = get(data, 'progress.progress');
  const levelsArray = [...Array(numLevels).keys()];
  const chartData = levelsArray.map((i) => ({
    levelNumber: (i + 1).toString(),
    levelTitle: get(results, `[${i}].levelTitle`, 'Loading...'),
    Started: get(results, `[${i}].startedCount`, 10),
    Completed: get(results, `[${i}].completedCount`, 4),
  }));

  const sharedLineProps = {
    type: 'monotone',
    strokeWidth: 1.5,
  };
  const moduleSplit = [8, 14, 20, 25, 30];

  if (loadingParent || loading) return <Loading />;

  if (couponCodes.length === 0) return <NoCouponCodes />;

  return (
    <ResponsiveContainer id="graph-container">
      <LineChart data={chartData}>
        <XAxis
          dataKey="levelNumber"
          type="number"
          tick={{ fontSize: '0.75rem' }}
          interval={0}
          domain={[1, 36]}
          padding={{ right: 5 }}
          scale="linear"
        >
          <Label
            value="Level Number"
            position="bottom"
            offset={-8}
            style={{ fill: '#5A6365' }}
          />
        </XAxis>
        <YAxis tick={{ fontSize: '0.75rem' }}>
          <Label
            angle={-90}
            value="Number of People"
            position="left"
            offset={-10}
            style={{ fill: '#5A6365', textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        {moduleSplit.map((i) => (
          <ReferenceLine
            x={i + 0.5}
            stroke="lightgray"
            strokeWidth={1}
            strokeDasharray="5 5"
          />
        ))}
        <LabelList />
        <Line dataKey="Started" stroke={colors[0]} {...sharedLineProps} />
        <Line dataKey="Completed" stroke={colors[1]} {...sharedLineProps} />
        <Legend verticalAlign="top" align="right" />
      </LineChart>
    </ResponsiveContainer>
  );
}

GuideProgress.propTypes = {
  couponCodes: PropTypes.arrayOf(PropTypes.string),
  loadingParent: PropTypes.bool,
};

GuideProgress.defaultProps = {
  couponCodes: [''],
  loadingParent: false,
};
