import React, { useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const ContainerStyled = styled.div`
  max-width: 600px;
  margin: var(--padding) auto;

  h2 {
    margin: var(--padding) 0 2rem 0;
    text-align: center;
  }

  input {
    flex-grow: 1;
    margin-right: 5px;
  }

  p {
    text-align: center;
    margin-top: 3rem;
  }

  .emailInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    border: 2px solid;
    border-color: var(--color-light-gray);
    margin: 0 auto;
    max-width: 450px;
  }
  .iconBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 1.6rem;
  }
  .iconBackgroundEnabled {
    background-color: var(--color-teal);
  }
  .iconBackgroundDisabled {
    background-color: var(--color-light-gray);
  }
  .iconEnabled {
    color: var(--color-white);
  }
  .iconDisabled {
    color: var(--color-concrete);
  }
`;

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && re.test(String(email).toLowerCase());
}
const API_URL = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_SERVER_URL;

function Login() {
  const [email, setEmail] = useState('');
  const [state, setState] = useState({
    submitted: false,
    loading: false,
    error: false,
  });

  const emailIsValid = validateEmail(email);

  const handleSubmitEmail = async (event) => {
    event.preventDefault();
    if (!emailIsValid) {
      return;
    }
    setState({ submitted: true, loading: true, error: false });
    try {
      await fetch(`${API_URL}/api/v1/users/authLogin`, {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      setState({ submitted: true, loading: false, error: false });
    } catch (e) {
      setState({ submitted: true, loading: false, error: true });
    }
  };

  const getStatusMessage = () => {
    const { loading, error, submitted } = state;
    if (loading) return 'Loading...';
    if (error) return 'An error has occurred. Please contact support for assistance.';
    if (submitted) return 'Success! Please check your email for an access link.';
    return null;
  };

  const handleChange = (event) => setEmail(event.target.value);

  const canSubmit = !state.submitted && emailIsValid;

  return (
    <ContainerStyled>
      <form method="POST" onSubmit={handleSubmitEmail}>
        <h2>{'To view your church\'s analytics, enter the associated email below.'}</h2>
        <div className="emailInput">
          <input placeholder="Email" type="email" onChange={handleChange} value={email} />
          <button
            type="submit"
            className={`iconBackground ${
              canSubmit ? 'iconBackgroundEnabled' : 'iconBackgroundDisabled'
            }`}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              className={canSubmit ? 'iconEnabled' : 'iconDisabled'}
              size="sm"
            />
          </button>
        </div>
      </form>
      {getStatusMessage() && <p>{getStatusMessage()}</p>}
    </ContainerStyled>
  );
}

export { Login as default };
