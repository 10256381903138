/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, createGlobalStyle } from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const theme = {
  breakpoints: {
    mobile: { value: 450 },
    tablet: { value: 700 },
    desktop: { value: 900 }
  }
}

theme.media = {};
Object.keys(theme.breakpoints).map(bp => {
  theme.media[bp] = (...args) => css`
    @media only screen and (max-width: ${theme.breakpoints[bp].value / 16}em) {
      ${css(...args)}
    }
  `;
});

theme.media.min = {};
Object.keys(theme.breakpoints).map(bp => {
  theme.media.min[bp] = (...args) => css`
    @media only screen and (min-width: ${theme.breakpoints[bp].value / 16}em) {
      ${css(...args)}
    }
  `;
});

const GlobalStyles = createGlobalStyle`
  html {
    --color-sea-monster: #054148;
    --color-brand-light-blue: #63b3be;
    --color-brand-light-blue-rgb: 99,179,190;
    --color-teal: #00BAA4;
    --color-teal-button: #00BF9D;
    --color-tennis-ball: #dbe442;
    --color-light-grey: #EBECEC;
    --color-light-gray: var(--color-light-grey);
    --color-grey: #7f8c8d;
    --color-gray: var(--color-grey);
    --color-dark-grey: #5A6365;
    --color-dark-gray: var(--color-dark-grey);
    --color-concrete: #BDC3C7;
    --color-white: white;
    --color-black: white;
    --color-graph-blue: rgb(21,170,191);
    --maxWidth: 1000px;
    --bs: 0 12px 24px rgba(3,3,3,0.1);
    --bs-darker: 0 12px 24px rgba(3,3,3,0.25);
    --padding: 1rem;
    --borderRadius: 12px;
    box-sizing: border-box;
    font-size: 16px;
    ${theme.media.tablet`
      font-size: 14px;`}
    ${theme.media.mobile`
      font-size: 12px;`}
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: Roboto, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetice Neue', sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1.5rem;
    color: var(--color-sea-monster);
    height: 100%;
  }
  a {
    text-decoration: none;
    color: var(--black);
  }
  a:hover {
    text-decoration: underline;
  }
  button {
    font-family: Roboto, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetice Neue', sans-serif;
    font-size: 1rem;
    color: var(--color-sea-monster);
    border: 0;
  }
  h1 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.3rem;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5625rem;
    letter-spacing: 0.8px;
  }
  input {
    font-family: Roboto, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetice Neue', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    border: none;
    color: var(--color-sea-monster);
  }
  input:focus {
    border: none;
    outline: none;
  }
  hr {
    border-width: 1px 0 0 0;
    border-top: 1px solid var(--color-light-gray);
    margin: var(--padding) 0;
  }
  .recharts-text {
    font-weight: bold;
  }
  #graph-container>div, #graph-container>div>svg {
    width: 100% !important;
    height: 100% !important;
  }
`;

const InnerStyles = styled.div`
  max-width: var(--maxWidth);
  width: 100%;
  padding: 0 var(--padding);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const PageStyles = styled.div`
  max-width: 1250px;
  height: 100%;
  margin: 0 auto;
  padding: var(--padding) var(--padding) 0 var(--padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default function Page({ children }) {
  return (
    <PageStyles>
      <GlobalStyles />
      <Header />
      <InnerStyles>{children}</InnerStyles>
      <Footer />
    </PageStyles>
  );
}

Page.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

Page.defaultProps = {
  children: null,
};
