import React, { useEffect, useState } from 'react';

import Page from './components/Page';
import Login from './components/Login';
import Dashboard from './components/dashboard';

const API_URL = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_SERVER_URL;

function App() {
  const token = new URLSearchParams(window.location.search).get('token');
  const [loading, setLoading] = useState(!!token);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const exchangeToken = async (t) => {
      try {
        const response = await fetch(`${API_URL}/api/v1/users/authVerify?token=${t}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (data.status === 'success') setLoggedIn(true);
      } catch (e) {
        // do nothing
      }
    };

    if (token) {
      exchangeToken(token);
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return (
      <Page>
        <h3>Loading...</h3>
      </Page>
    );
  }

  if (loggedIn) {
    return (
      <Page>
        <Dashboard />
      </Page>
    );
  }

  return (
    <Page>
      <Login />
    </Page>
  );
}

export default App;
