import React from 'react';

import styled from 'styled-components';
import Loader from 'react-loader-spinner';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default function Loading() {
  return (
    <LoadingContainer>
      <Loader type="BallTriangle" color="#00BAA4" height="8rem" width="8rem" timeout={null} />
    </LoadingContainer>
  );
}
