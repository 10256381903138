/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import styled from 'styled-components';
import Multiselect from 'multiselect-react-dropdown';

import DashboardHeader from './Header';
import GraphContainer from './GraphContainer';
import OrdersWorkbooks from '../charts/OrdersWorkbooks';
import Groups from '../charts/Groups';
import Lanes from '../charts/Lanes';
import GuideProgress from '../charts/GuideProgress';

const USER_CHURCH = gql`
  query {
    user {
      _id
      roles
      church {
        name
        couponCodes
      }
      couponCodes
    }
  }
`;

const DashboardStyled = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  .header {
    margin: 4rem 0;
  }
`;

const FilterStyled = styled.div`
  p {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  div {
    margin-top: 5px;
  }
  button.select {
    margin: 4px;
    padding: 4px 10px 4px 10px;
    border: 1px solid var(--color-sea-monster);
    border-radius: 4px;
  }
  button.select-all {
    background: var(--color-sea-monster);
    color: var(--color-white);
  }
  button.select-none {
    color: var(--color-sea-monster);
    background: var(--color-white);
  }
`;

const MultiSelectStyle = {
  chips: {
    background: 'var(--color-teal)',
    borderRadius: '4px',
  },
  searchBox: {
    border: '2px solid',
    borderColor: 'var(--color-light-gray)',
    borderRadius: '4px',
    padding: '5px',
  },
  option: {
    background: 'var(--color-white)',
    color: 'var(--color-dark-gray)',
  },
  multiselectContainer: {
    color: 'var(--color-dark-gray)',
  },
};

export default function DashboardPage() {
  const [couponCodes, setCouponCodes] = useState([]);
  const { data, loading, error } = useQuery(USER_CHURCH);
  const userCouponCodes = get(data, 'user.couponCodes', []);
  const churchCouponCodes = get(data, 'user.church.couponCodes', []);

  useEffect(() => {
    if (!loading && userCouponCodes.length) {
      setCouponCodes(userCouponCodes);
    } else if (!loading && churchCouponCodes.length) {
      setCouponCodes(churchCouponCodes);
    }
  }, [data, loading]);

  const churchName = get(data, 'user.church.name');

  let availableCouponCodes = userCouponCodes;
  if (!availableCouponCodes.length) {
    availableCouponCodes = churchCouponCodes;
  }

  const charts = [
    {
      title: 'Orders & Workbooks',
      component: <OrdersWorkbooks couponCodes={couponCodes} loadingParent={loading} />,
    },
    {
      title: 'Groups',
      component: <Groups couponCodes={couponCodes} loadingParent={loading} />,
    },
    {
      title: 'Number of People in Each Lane',
      component: <Lanes couponCodes={couponCodes} loadingParent={loading} />,
    },
    {
      title: 'App Progress',
      component: <GuideProgress couponCodes={couponCodes} loadingParent={loading} />,
    },
  ];

  const onSelect = (selectedList) => {
    setCouponCodes(selectedList.map((li) => li.name));
  };

  const onRemove = (selectedList) => {
    setCouponCodes(selectedList.map((li) => li.name));
  };

  const available = availableCouponCodes.map((cc) => ({
    name: cc || 'None',
    id: availableCouponCodes.indexOf(cc),
  }));
  const selected = couponCodes.map((cc) => ({
    name: cc || 'None',
    id: availableCouponCodes.indexOf(cc),
  }));


  const CouponCodesFilter = () => {

  const inputFieldStyle = {
    inputField: {
      display: couponCodes.length === availableCouponCodes.length ? 'none' : 'block',
    },
  };

  return (
    <FilterStyled>
      <p>
        Apply the appropriate coupon code(s) for the church data you would like to view below.
      </p>
      <Multiselect
        options={available}
        selectedValues={selected}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
        closeIcon="cancel"
        avoidHighlightFirstOption
        placeholder="Add Coupon Codes..."
        style={{ ...MultiSelectStyle, ...inputFieldStyle }}
      />
      <div>
        <button
          type="submit"
          onClick={() => setCouponCodes(availableCouponCodes)}
          className="select select-all"
        >
          Apply All
        </button>
        <button
          type="submit"
          onClick={() => setCouponCodes([])}
          className="select select-none"
        >
          Remove All
        </button>
      </div>
    </FilterStyled>
  );
};

  // TODO Error handling

  return (
    <DashboardStyled>
      <div className="header">
        <DashboardHeader churchName={loading ? 'Loading...' : churchName} />
        <CouponCodesFilter />
      </div>

      {error && <p style={{ color: 'red' }}>{error.stack}</p>}
      {charts.map((chart) => (
        <GraphContainer title={chart.title}>
          {chart.component}
        </GraphContainer>
      ))}
    </DashboardStyled>
  );
}
