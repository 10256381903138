import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

import Loading from '../Loading';
import NoCouponCodes from '../NoCouponCodes';
import CustomTooltip from './Tooltip';

const ORDERS = gql`
  query ($select: OrderSelectInput) {
    orders(select: $select) {
      totalCount
      workbookCount
      unlockedUserCount
    }
  }
`;

const colors = ['rgb(21,170,191)', 'rgb(18, 184, 134)', 'rgb(64, 192, 87)'];

export default function OrdersWorkbooks({ couponCodes, loadingParent }) {
  const { data, loading } = useQuery(ORDERS, {
    variables: {
      select: { couponCodes },
    },
    skip: couponCodes.length === 0,
  });

  const plotData = [
    {
      name: 'Orders',
      count: get(data, 'orders.totalCount', 0),
      description:
        'Count of orders placed on getfreedup.com using the above coupon codes.',
    },
    {
      name: 'Workbooks',
      count: get(data, 'orders.workbookCount', 0),
      description: 'Count of workbooks shipped.',
    },
    {
      name: 'Subscribed Users',
      count: get(data, 'orders.unlockedUserCount', 0),
      description:
        'Count of app users who have been linked to an order (and therefore have access to all content).',
    },
  ];

  if (loadingParent || loading) return <Loading />;

  if (couponCodes.length === 0) return <NoCouponCodes />;

  return (
    <ResponsiveContainer id="graph-container">
      <BarChart data={plotData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="count" radius={[5, 5, 0, 0]}>
          {plotData.map((entry, index) => (
            <Cell fill={colors[index]} key={`cell-${index}`} /> // eslint-disable-line react/no-array-index-key
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

OrdersWorkbooks.propTypes = {
  couponCodes: PropTypes.arrayOf(PropTypes.string),
  loadingParent: PropTypes.bool,
};

OrdersWorkbooks.defaultProps = {
  couponCodes: [''],
  loadingParent: false,
};
