import React from 'react';
import styled from 'styled-components';

const domain = 'https://www.getfreedup.com';

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  a {
    font-size: 1rem;
    padding-left: 24px;
    font-weight: 700;
    word-wrap: none;
    vertical-align: middle;
    margin-block-start: 0;
  }

  .first {
    padding-left: 0;
  }

  .last {
    padding-right: var(--padding);
  }

  p {
    font-size: 1rem;
    vertical-align: middle;
    margin: 0;
    margin-bottom: 0;
  }

  .divider {
    font-size: 1.25em;
    margin-bottom: 0;
    padding-left: 24px;
    color: var(--color-teal);
  }

  .copyright {
    margin-left: auto;
    font-weight: normal;
  }

  @media only screen and (max-width: 1050px) {
    flex-flow: column nowrap;
    align-items: flex-start;

    a {
      padding-left: 0;
      text-align: left;
    }
    .divider {
      padding: 0;
      line-height: 1.5em;
      font-size: 1.5em;
      transform: rotate(90deg) translate(0, -25%);
    }
  }
`;

export default function Footer() {
  return (
    <ContainerStyled>
      <a className="first" href={`${domain}/resources/`}>
        Resources
      </a>
      <a href={`${domain}/about-us/`}>About</a>
      <a href={`${domain}/contact-us/`}>Contact</a>
      <a href={`${domain}/privacy/`}>Privacy</a>
      <a href={`${domain}/terms-of-use/`}>Terms</a>
      <a href={`${domain}/faq/`}>FAQ</a>
      <p className="divider">|</p>
      <a href={`${domain}/church/`}>For Churches</a>
      <a className="last" href={`${domain}/resource-kit/`}>
        Church Resource Kit
      </a>
      <p className="copyright">© Copyright 2021 FreedUp</p>
    </ContainerStyled>
  );
}
