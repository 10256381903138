/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GraphStyled = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  h3 {
    color: var(--color-dark-gray);
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .chartBorder {
    border: 2px solid;
    border-color: var(--color-light-gray);
    border-radius: 4px;
    margin-bottom: 2rem;
    padding: 2rem 2rem 0rem 0rem;
  }
  .chart {
    height: 350px;
    display: block;
    padding: 2px;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 2rem;
    .chartBorder {
      margin-bottom: 1rem;
      padding: 1rem 1rem 0rem 0rem;    }
    .chart {
      height: 250px;
    }
  }
`;

export default function GraphContainer({ title, children }) {
  return (
    <GraphStyled>
      <h3>{title}</h3>
      <div className="chartBorder">
        <div className="chart">
          {children}
        </div>
      </div>
      <hr />
    </GraphStyled>

  );
}

GraphContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
