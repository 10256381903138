import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeaderStyled = styled.div`
  
  p {
    margin: var(--padding) 0;
  }
  .title {
    text-transform: uppercase;
    font-weight: 500;
  }
  .date {
    font-style: italic;
    color: var(--color-gray);
  }
  h1 {
    border-left: 3px solid;
    border-left-color: var(--color-teal);
    margin: 0;
    padding-left: var(--padding);
  }
  @media only screen and (max-width: 650px) {
    margin: 2rem 0;
    p {
      text-align: center;
      margin: 4px 0;
    }
    h1 {
      text-align: center;
      border-left: 0px solid;
      margin: 0;
      padding-left: 0;
    }
  }
`;

const DashboardHeader = ({
  churchName,
}) => {
  const getFormattedDate = () => new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <HeaderStyled>
      <p className="title">FreedUp Analytics</p>
      <h1>{churchName}</h1>
      <p className="date">{`As of ${getFormattedDate()}`}</p>
    </HeaderStyled>
  );
};

DashboardHeader.propTypes = {
  churchName: PropTypes.string.isRequired,
};

export default DashboardHeader;
