/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Cell,
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

import Loading from '../Loading';
import NoCouponCodes from '../NoCouponCodes';

const LANES = gql`
  query ($select: ResponseSelectInput) {
    lanes(select: $select) {
      lane1Count
      lane2Count
      lane3Count
    }
  }
`;

const COLORS = ['rgb(22, 160, 133)', 'rgb(93, 176, 187)', 'rgb(218, 39, 110)'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`${payload.name}: ${value}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default function Lanes({ couponCodes, loadingParent }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, loading } = useQuery(LANES, {
    variables: {
      select: { couponCodes },
    },
    skip: couponCodes.length === 0,
  });

  const onPieEnter = (_, index) => setActiveIndex(index);

  const chartdata = [
    { name: 'Lane 1', value: get(data, 'lanes.lane1Count', 0) },
    { name: 'Lane 2', value: get(data, 'lanes.lane2Count', 0) },
    { name: 'Lane 3', value: get(data, 'lanes.lane1Count', 0) },
  ];

  if (loadingParent || loading) return <Loading />;

  if (couponCodes.length === 0) return <NoCouponCodes />;

  return (
    <ResponsiveContainer id="graph-container">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={chartdata}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {chartdata.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

Lanes.propTypes = {
  couponCodes: PropTypes.arrayOf(PropTypes.string),
  loadingParent: PropTypes.bool,
};

Lanes.defaultProps = {
  couponCodes: [],
  loadingParent: false,
};
