import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';

const TooltipStyled = styled.div`
  background-color: rgba(250, 250, 250, 0.8);
  padding: 4px;
  border: 2px solid;
  border-color: var(--color-light-gray);
  border-radius: 4px;
  max-width: 200px;
  .text {
    padding: 0;
    margin: 0;
  }
  .title {
    font-weight: bold;
  }
  .count {
    font-weight: bold;
  }
  .description {
    padding-top: 4px;
  }
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipStyled>
        <p className="text title">{label}</p>
        <p className="text count">{get(payload, '[0].payload.count')}</p>
        <p className="text description">{get(payload, '[0].payload.description')}</p>
      </TooltipStyled>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        count: PropTypes.number,
        description: PropTypes.string,
      }),
    }),
  ),
  label: PropTypes.string,
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  label: '',
};

export { CustomTooltip as default };
