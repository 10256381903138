import React from 'react';

import styled from 'styled-components';

const NoCouponCodesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default function NoCouponCodes() {
  return (
    <NoCouponCodesContainer>
      <h3>Please select at least one coupon code.</h3>
    </NoCouponCodesContainer>
  );
}
