import React from 'react';
import styled from 'styled-components';

import logo from '../assets/verticalLogoDark.png';
import useIsMobile from '../utils/useIsMobile';

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
  }

  h2 {
    color: var(--color-teal);
    margin: 0;
    margin-left: 2rem;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 47.25px;
  }

  button {
    position: relative;
    display: inline-block;
    box-shadow: var(--bs);
    border-radius: 8px;
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    transition: all 0.25s ease-in-out;
    will-change: transform;
    z-index:1;
  }
  button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 0;
    box-shadow: var(--bs-darker);
    transition: opacity 0.25s ease-in-out;
  }
  button:hover, button:focus {
    transform: translateY(3px) translateX(3px);
  }
  button:hover::after {
    opacity: 1;
  }

  h3 {
    margin: 0px 10px;
  }
`;

const MobileContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    box-shadow: var(--bs);
    border-radius: 8px;
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    height: 35px;
  }
  h2 {
    color: var(--color-teal);
    text-align: center;
  }
  h3 {
    margin: 0px 10px;
  }
`;

export default function Header() {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <MobileContainerStyled>
        <div>
          <a href="https://www.getfreedup.com/">
            <img src={logo} alt="FreedUp" width={60} height={47.25} />
          </a>
          <a href="https://www.getfreedup.com/contact-us" alt="Contact Us">
            <button type="button">
              <h3>Support</h3>
            </button>
          </a>
        </div>
        <h2>
          <span>Church Analytics Dashboard</span>
        </h2>
      </MobileContainerStyled>
    );
  }
  return (
    <ContainerStyled>
      <div>
        <a href="https://www.getfreedup.com/">
          <img src={logo} alt="FreedUp" width={60} height={47.25} />
        </a>
        <h2>
          <span>Church Analytics Dashboard</span>
        </h2>
      </div>

      <a href="https://www.getfreedup.com/contact-us" alt="Contact Us">
        <button type="button">
          <h3>Support</h3>
        </button>
      </a>
    </ContainerStyled>
  );
}
