import { useEffect, useState } from 'react';

const MOBILE_MAX_WIDTH = 550;

function debounce(fn, ms, ...args) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(null, ...args);
    }, ms);
  };
}

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_MAX_WIDTH);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      const newIsMobile = window.innerWidth < MOBILE_MAX_WIDTH;
      if (isMobile !== newIsMobile) setIsMobile(!isMobile);
    }, 100);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  });

  return isMobile;
};

export default useIsMobile;
