import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

import Loading from '../Loading';
import NoCouponCodes from '../NoCouponCodes';
import CustomTooltip from './Tooltip';

const GROUPS = gql`
  query ($select: GroupSelectInput) {
    groups(select: $select) {
      totalCount
      userCount
    }
  }
`;

const colors = ['rgb(21,170,191)', 'rgb(18, 184, 134)', 'rgb(64, 192, 87)'];

export default function Groups({ couponCodes, loadingParent }) {
  const { data, loading } = useQuery(GROUPS, {
    variables: {
      select: { couponCodes },
    },
    skip: couponCodes.length === 0,
  });

  const chartData = [
    {
      name: 'People in Groups',
      count: get(data, 'groups.userCount', 0),
      description:
        'Count of individual users participating in a group (based on the group sync feature).',
    },
    {
      name: 'Groups',
      count: get(data, 'groups.totalCount', 0),
      description:
        'Total number of groups with people associated with the above coupon codes.',
    },
  ];

  if (loadingParent || loading) return <Loading />;

  if (couponCodes.length === 0) return <NoCouponCodes />;

  return (
    <ResponsiveContainer id="graph-container">
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="count" radius={[5, 5, 0, 0]}>
          {chartData.map((entry, index) => (
            <Cell fill={colors[index]} key={`cell-${index}`} /> // eslint-disable-line react/no-array-index-key
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

Groups.propTypes = {
  couponCodes: PropTypes.arrayOf(PropTypes.string),
  loadingParent: PropTypes.bool,
};

Groups.defaultProps = {
  couponCodes: [''],
  loadingParent: false,
};
